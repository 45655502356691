<template>
  <div class="d-flex justify-center align-center">
    <v-progress-circular
      :size="sizeMap[size]"
      :width="widthMap[size]"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    size: {
      type: String,
      default: 'large',
      validator(value) {
        return ['small', 'medium', 'large'].includes(value);
      },
    },
  },
  computed: {
    sizeMap() {
      return {
        small: 32,
        medium: 48,
        large: 64,
      };
    },
    widthMap() {
      return {
        small: 2,
        medium: 4,
        large: 6,
      };
    },
  },
};
</script>
