<template>
  <div>
    <v-card class="py-4 px-2" v-if="formFields.length">
      <v-card-title>
        <h4>Custom Form Fields</h4>
      </v-card-title>
      <v-card-text>
        <v-form class="mt-10" ref="ccfform" v-model="valid" lazy-validation :readonly="isReadonly">
          <v-row>
            <v-col v-for="field in formFields" :key="field.id" :cols="field.colspan">
              <v-text-field
                v-bind="inputProps"
                v-if="field.field_type === 'Text'"
                :label="field.field_label"
                v-model="field.invoiceCustomFormFields.field_value"
                outlined
                :rules="[
                  required(field.is_required),
                  betweenLength(field.min_length, field.max_length, field.is_required, field.has_length_validation),
                  betweenValue(field.min_value, field.max_value, field.is_required, field.has_value_validation),
                ]"
              ></v-text-field>
              <v-text-field
                v-bind="inputProps"
                v-else-if="field.field_type === 'Number'"
                type="number"
                :label="field.field_label"
                v-model="field.invoiceCustomFormFields.field_value"
                outlined
                :rules="[
                  required(field.is_required),
                  betweenValue(field.min_value, field.max_value, field.is_required, field.has_value_validation),
                  decimalPlaces(field.is_required),
                ]"
              ></v-text-field>
              <v-select
                v-bind="inputProps"
                v-else-if="field.field_type === 'Dropdown'"
                :label="field.field_label"
                v-model="field.invoiceCustomFormFields.field_value"
                :items="field.field_options"
                item-text="label"
                item-value="value"
                :rules="[required(field.is_required)]"
                outlined
                dense
              ></v-select>
              <v-checkbox
                v-bind="inputProps"
                v-else-if="field.field_type === 'Checkbox'"
                :rules="[required(field.is_required)]"
                :label="field.field_label"
                v-model="field.invoiceCustomFormFields.field_value"
              ></v-checkbox>
              <v-radio-group
                v-bind="inputProps"
                v-else-if="field.field_type === 'Radio'"
                v-model="field.invoiceCustomFormFields.field_value"
                :rules="[required(field.is_required)]"
                :label="field.field_label"
                style="margin-top: -10px"
              >
                <v-radio
                  v-for="option in field.field_options"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                ></v-radio>
              </v-radio-group>
              <date-picker
                :readonly="isReadonly"
                v-else-if="field.field_type === 'Date'"
                :label="field.field_label"
                :hide-details="false"
                v-model="field.invoiceCustomFormFields.field_value"
                :rules="[
                  required(field.is_required),
                  betweenDateValue(field.min_value, field.max_value, field.is_required, field.has_value_validation),
                ]"
              ></date-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn :disabled="isReadonly" @click="submit">Submit</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import configApi from '@/apis/config';
import DatePicker from '@/components/DatePicker';
import { betweenLength, betweenValue, required as checkRequired, decimal, betweenDateValue } from '@/util/rules';
import { inputProps } from '@/util';
export default {
  name: 'CustomFormFieldsDisplay',

  props: {
    module: {
      type: String,
      default: 'Invoice',
    },
    section: {
      type: String,
      required: true,
    },

    parentId: {
      type: Number,
      required: true,
    },
    isReadonly: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    DatePicker,
  },

  data() {
    return {
      inputProps: { ...inputProps, dense: true },
      formFields: [],
      valid: false,
    };
  },

  computed: {
    required() {
      return (isRequired) => (value) => {
        if (isRequired) return checkRequired(value);
        return true;
      };
    },

    decimalPlaces() {
      return (isRequired) => (value) => {
        if (value === null || (!isRequired && !value)) return true;
        return decimal(value) || 'Input value must be a number.';
      };
    },
    betweenLength,
    betweenValue,
    betweenDateValue,
  },

  methods: {
    getRules(field) {
      const rules = [];
      if (field.required) {
        rules.push(this.required);
      }
      if (field.has_length_validation) {
        rules.push(this.between(field.min_length, field.max_length, field.is_required));
      }
      if (field.has_value_validation) {
        rules.push(this.between(field.min_value, field.min_value, field.is_required));
      }

      return rules;
    },

    async submit() {
      if (this.$refs.ccfform.validate()) {
        try {
          await configApi.saveCustomFormFieldValues({
            module: this.module,
            section: this.section,
            formFields: this.formFields,
            parentId: this.parentId,
          });
          this.$myalert.success('Form saved successfully');
        } catch (error) {
          this.$myalert.error(error?.message);
        }
      }
    },
  },

  async mounted() {
    try {
      this.formFields = await configApi.getCustomFormFieldsForFillup(this.module, this.section, this.parentId);
    } catch (error) {
      this.$myalert.error(error?.message);
    }
  },
};
</script>
