import { render, staticRenderFns } from "./Chip.vue?vue&type=template&id=632c4fd6&scoped=true"
import script from "./Chip.vue?vue&type=script&lang=js"
export * from "./Chip.vue?vue&type=script&lang=js"
import style0 from "./Chip.vue?vue&type=style&index=0&id=632c4fd6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "632c4fd6",
  null
  
)

export default component.exports