<template>
  <v-combobox v-bind="computedAttrs" :value="modelValue" @input="updateModelValue">
    <template v-if="showSelectAll" v-slot:prepend-item>
      <v-list-item link @click="toggleSelectAll" dense>
        <v-list-item-content>
          <v-checkbox v-model="allSelectedValue" @click.stop label="Select All" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
</template>
<script>
import { inputProps } from '@/util';
export default {
  props: {
    value: [String, Number, Array, Object],
    showSelectAll: { type: Boolean, default: false },
  },
  data() {
    return {
      isAllSelected: false,
    };
  },
  computed: {
    allSelectedValue: {
      get() {
        if (!this.showSelectAll) return false;

        return this.modelValue.length === this.$attrs.items.length;
      },
      set(isChecked) {
        if (!this.showSelectAll) return;

        if (!isChecked) this.$emit('input', []);
        else this.$emit('input', this.$attrs.items);
      },
    },
    computedAttrs() {
      return {
        ...this.$attrs,
        ...inputProps,
        clearable: true,
        chips: true,
        deletableChips: true,
        hideSelected: true,
      };
    },
    modelValue() {
      return this.value;
    },
  },
  methods: {
    updateModelValue(value) {
      this.$emit('input', value);
    },
    toggleSelectAll() {
      this.allSelectedValue = !this.allSelectedValue;
    },
  },
};
</script>
