<template>
  <v-chip :class="computedClasses" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </v-chip>
</template>

<script>
export default {
  props: {
    wrap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedClasses() {
      return {
        'wrap-chip': this.wrap,
        'eza-chip': true,
      };
    },
  },
};
</script>

<style scoped>
.wrap-chip {
  white-space: normal !important;
  max-width: 100%;
}
.eza-chip {
  height: auto !important;
}
</style>
