<template>
  <v-card class="card-bordered fill-height" flat :style="{ borderColor: computedColor }">
    <v-row no-gutters class="fill-height">
      <v-col
        v-if="$slots.left"
        cols="2"
        :class="`text-h4 text-center card-bordered-left fill-height ${style.padding}`"
        :style="{ backgroundColor: computedColor }"
      >
        <slot name="left"></slot>
      </v-col>
      <v-col :cols="$slots.left ? 10 : 12" :class="`card-bordered-body fill-height ${style.padding}`">
        <slot name="body"></slot>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { colors } from '@/util';
export default {
  name: 'CardBordered',
  props: {
    color: {
      type: String,
      default: 'blue',
    },
  },
  data() {
    return {
      style: {
        padding: 'pa-2',
      },
    };
  },
  computed: {
    computedColor() {
      return colors[this.color] || this.colors.blue;
    },
  },
};
</script>

<style scoped>
.card-bordered {
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid;
}

.card-bordered-left {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.card-bordered-body {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
