<template>
  <v-dialog v-model="dialog" max-width="80vw" persistent :width="width">
    <v-card :dark="dark" :loading="loading">
      <v-card-title :class="classColor" class="text-h5 lighten-4" :style="styleColor" v-if="$slots.header">
        <slot name="header"></slot>
      </v-card-title>
      <v-card-text class="scrollable-text py-4">
        <slot name="body"></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions"></slot>
        <v-btn color="red darken-1" :loading="loading" text @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    color: { type: String, default: 'blue' },
    dark: { type: Boolean, default: false },
    width: { type: String },
    loading: { type: Boolean, default: false },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    classColor() {
      if (this.color.startsWith('#')) {
        return '';
      }

      return this.color;
    },
    styleColor() {
      if (!this.color.startsWith('#')) {
        return '';
      }
      return {
        'background-color': this.color,
      };
    },
  },
  methods: {
    closeDialog() {
      this.$emit('input', false);
    },
  },
};
</script>

<style scoped>
.scrollable-text {
  max-height: 75vh;
  overflow-y: auto;
}
</style>
