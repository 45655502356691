import { toDateString } from './index';

export const required = (value) => {
  if (value === undefined || value === null || value === '') {
    return 'This field is required';
  }
  return true;
};

export const numeric = (value) => {
  const pattern = /^-?[0-9]*(\.[0-9]+)?$/;
  if (value !== '' && !pattern.test(value)) {
    return 'This field must be a numeric value';
  }
  return true;
};

export const decimal = (value) => {
  const pattern = /^-?\d+(\.\d{1,2})?$/;
  if (value !== '' && !pattern.test(value)) {
    return 'Only up to two decimals are allowed';
  }
  return true;
};

export const min = (min) => {
  return (value) => {
    const numericValue = parseFloat(value);
    const minValue = parseFloat(min);

    if (value !== '' && numericValue < minValue) {
      return `This field must be greater than ${minValue}`;
    }
    return true;
  };
};

export const max = (max) => {
  return (value) => {
    const numericValue = parseFloat(value);
    const maxValue = parseFloat(max);

    if (value !== '' && numericValue >= maxValue) {
      return `This field must be less than ${maxValue}`;
    }
    return true;
  };
};

export const date = (value) => {
  if (value === '' || value === undefined || value === null) {
    return true;
  }

  let dateValue = new Date(value);
  if (!isNaN(dateValue.getTime())) {
    return true;
  }

  return 'This field must be a valid date';
};

/**
 *
 * @param {string} minDate
 * @param {string} errorMessage
 * @returns {boolean|string}
 */
export const minDate = (minDate, errorMessage = 'Date must be on or after {date}') => {
  return (value) => {
    if (value === '' || value === undefined || value === null) return true;

    const dateValue = new Date(value);
    dateValue.setHours(0, 0, 0, 0);

    const minDateValueFull = new Date(minDate.split('T')[0]);
    const minDateValue = new Date(minDateValueFull.valueOf() + minDateValueFull.getTimezoneOffset() * 60 * 1000);

    if (dateValue >= minDateValue) return true;

    return errorMessage.replace(/{date}/gi, toDateString(minDate));
  };
};

/**
 *
 * @param {string} maxDate
 * @param {string} errorMessage
 * @returns {boolean|string}
 */
export const maxDate = (maxDate, errorMessage = 'Date must be on or before {date}') => {
  return (value) => {
    if (value === '' || value === undefined || value === null) return true;

    const dateValue = new Date(value);
    dateValue.setHours(0, 0, 0, 0);

    const maxDateValueFull = new Date(maxDate.split('T')[0]);
    const maxDateValue = new Date(maxDateValueFull.valueOf() + maxDateValueFull.getTimezoneOffset() * 60 * 1000);

    if (dateValue <= maxDateValue) return true;

    return errorMessage.replace(/{date}/gi, toDateString(maxDate));
  };
};

/**
 *
 * @param {string[]} excludedDates
 * @returns {boolean|string}
 */
export const allowedDates = (excludedDates) => {
  return (value) => {
    if (value === '' || value === undefined || value === null) return true;
    if (!excludedDates.length) return true;

    let dateValue = new Date(value).setHours(0, 0, 0, 0);

    let isExcluded = excludedDates.some((excludedDate) => {
      let excludedDateValue = new Date(excludedDate).setHours(0, 0, 0, 0);
      return dateValue === excludedDateValue;
    });

    if (isExcluded) return 'The selected date is not available. Please choose another date.';

    return true;
  };
};

/**
 * Validates that a number has up to a maximum number of decimal places.
 *
 * @param {number} maxDecimals - Maximum number of decimal places allowed.
 * @returns {Function} - A validator function that takes a value and returns a validation result.
 */
export const decimalPlaces = (maxDecimals) => {
  const pattern = new RegExp(`^-?\\d+(\\.\\d{0,${maxDecimals}})?$`);
  return (value) => {
    if (value !== '' && !pattern.test(value)) {
      return `Only up to ${maxDecimals} decimal places are allowed`;
    }
    return true;
  };
};

/**
 * Returns a validation function that checks if the length of the input value is between the specified minimum and maximum values.
 *
 * @returns {Function} The validation function.
 * @param {number} min - The minimum length allowed.
 * @param {number} max - The maximum length allowed.
 * @param {boolean} isRequired - Indicates if the input value is required.
 * @param {boolean} hasLengthValidation - Indicates if length validation is enabled.
 * @param {string|boolean} value - The input value to be validated.
 * @returns {boolean|string} Returns `true` if the input value is valid, or an error message if the input value is invalid.
 */
export const betweenLength = () => {
  return (min, max, isRequired, hasLengthValidation) => (value) => {
    if (value === null || (!isRequired && !value) || !hasLengthValidation) return true;
    return (value.length >= min && value.length <= max) || `Input must be between ${min} and ${max} characters.`;
  };
};

/**
 * Returns a validation function that checks if the input value is between the specified minimum and maximum values.
 *
 * @returns {Function} The validation function.
 * @param {number} min - The minimum value.
 * @param {number} max - The maximum value.
 * @param {boolean} isRequired - Indicates if the value is required.
 * @param {boolean} hasValueValidation - Indicates if value validation is enabled.
 * @returns {(boolean|string)} Returns `true` if the value is between the specified range, or an error message if the value is not valid.
 */
export const betweenValue = () => {
  return (min, max, isRequired, hasValueValidation) => (value) => {
    if (value === null || (!isRequired && !value) || !hasValueValidation) return true;
    return (
      (Number(value) >= Number(min) && Number(value) <= Number(max)) || `Input value must be between ${min} and ${max}.`
    );
  };
};

/**
 * Validates if the given value is between the specified range.
 * @param {Date} min - The minimum date value of the range.
 * @param {Date} max - The maximum date value of the range.
 * @param {boolean} isRequired - Indicates if the value is required.
 * @param {boolean} hasValueValidation - Indicates if value validation is required.
 * @param {Date} value - The value to be validated.
 * @returns {boolean|string} Returns true if the value is between the range, otherwise returns an error message.
 */
export const betweenDateValue = () => {
  return (min, max, isRequired, hasValueValidation) => (value) => {
    if (value === null || (!isRequired && !value) || !hasValueValidation) return true;
    const dateVal = new Date(value).setHours(0, 0, 0, 0);
    const dateMin = new Date(min).setHours(0, 0, 0, 0);
    const dateMax = new Date(max).setHours(0, 0, 0, 0);
    return (dateVal >= dateMin && dateVal <= dateMax) || `Input value must be between ${min} and ${max}.`;
  };
};
